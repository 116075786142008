import { Link, Image, Name, Description } from './packLink.styles';

const PackLink = ({ packUrl, imageUrl, name, description }) => (
  <Link href={packUrl} target="_blank" referrerPolicy="no-referrer">
    <Image src={imageUrl}></Image>
    <div>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </div>
  </Link>
);

export default PackLink;
