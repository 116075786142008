import styled from 'styled-components';

export const NavBar = styled.div`
  background: #d7f4ff60;
  padding: 15px 25px;
  box-shadow: 4px 4px 20px 5px #d7f4ff20;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  justify-items: center;
  align-items: center;
  @media only screen and (max-width: 693px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }
`;

export const Title = styled.h1`
  margin: 0 30px;
  font-size: 38px;
  color: white;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
`;

export const Image = styled.img`
  height: 40px;
  border-radius: 4px;
  background: none;
  @media only screen and (max-width: 560px) {
    height: 30px;
    margin: 0 5px;
  }
`;

export const Container = styled.a`
  max-width: 140px;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media only screen and (max-width: 560px) {
    max-width: 100%;
  }
`;

export const Text = styled.h2`
  color: white;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
`;

export const Arrow = styled(Title)`
  margin: 0 3px;
  font-size: 60px;
  line-height: 40px;
`;

export const LogoBox = styled.div`
  min-width: 150px;
  @media only screen and (max-width: 693px) {
    display: none;
  }
`;
