import { Footer, Text, Button, MailIcon, Link } from './feedback.styles';

function Feedback() {
  return (
    <Footer>
      <Text>Questions? Feedback? We want to hear from you!</Text>
      <Link
        rel="noreferrer"
        target="_blank"
        href="mailto:support@littlerobot.ca?subject=Coda Export App Support"
      >
        <Button>
          <MailIcon />
          <p>Contact us</p>
        </Button>
      </Link>
    </Footer>
  );
}

export default Feedback;
