import styled from 'styled-components';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const Footer = styled.div`
  background: #d7f4ff60;
  padding: 15px;
  box-shadow: 4px 4px 20px 5px #d7f4ff20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

export const Text = styled.h2`
  color: white;
  margin: 10px 10px;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

export const MailIcon = styled(MailOutlineIcon)`
  font-size: 20px !important;
  margin-right: 8px;
`;

export const Button = styled.button`
  padding: 5px 20px;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background: #234970;
  margin: 10px 10px;
  color: #fff;
  border: none;
  min-width: 184px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 2px #00000020;
  &:hover {
    cursor: pointer;
    background: #204266;
  }
  p {
    margin: 0;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
