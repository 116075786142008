import XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  Message,
  ButtonBox,
  Button,
  Link,
  IconContainer,
  MessageBox,
} from './export.styles';
import { format } from 'date-fns';
import { Download } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import mixpanelEvent from '../util/mixpanel';

const GET_TABLE = gql`
  query GetTable($uuid: String!) {
    getTable(uuid: $uuid) {
      tableRows {
        headers
        values
      }
      tableName
      updatedAt
      type
      email
    }
  }
`;

function ExportMessage() {
  const location = useLocation();
  const id = location.pathname.slice(8);

  const { data, error, loading } = useQuery(GET_TABLE, {
    variables: { uuid: id },
  });

  const exportTable = async () => {
    const formattedDate = format(new Date(), 'yyyy-MM-dd');

    const tableRows = data.getTable.tableRows;
    const tableData = tableRows.map((row) => {
      const data = {};
      for (let i = 0; i < row.headers.length; i++) {
        data[row.headers[i]] = row.values[i];
      }
      return data;
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(tableData);

    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(
      wb,
      `${data.getTable.tableName} - ${formattedDate}.${data.getTable.type}`
    );
    mixpanelEvent('Table exported', { Email: data.getTable.email });
  };

  if (error)
    return (
      <MessageBox>
        <Message>
          An error has occurred. Please make sure your button contains the
          correct URL. You can now close this window and try again.
        </Message>
        <Message>
          If the issue persists, you can{' '}
          <Link href={'/'}>try making a new URL</Link> or{' '}
          <Link href="mailto:support@littlerobot.ca?subject=Coda Export App Support">
            contact us
          </Link>{' '}
          for help.
        </Message>
      </MessageBox>
    );

  return (
    <>
      <MessageBox>
        <Message>
          {data
            ? `Fetching complete! Your table, ${data?.getTable?.tableName}, is ready to download. Note that there is sometimes a delay in receiving updated data after making changes in Coda. If any of your recent changes don't appear in the exported table, try clicking your Coda button again in a few minutes.`
            : 'Fetching your Coda table...'}
        </Message>
      </MessageBox>
      <ButtonBox>
        <Button onClick={exportTable} disabled={loading}>
          <IconContainer>
            {data ? (
              <Download fontSize="inherit" />
            ) : (
              <CircularProgress color="inherit" size="26px" />
            )}
          </IconContainer>
          <span>Download Table</span>
        </Button>
      </ButtonBox>
    </>
  );
}

export default ExportMessage;
