import Nav from './components/nav';
import Announcement from './components/announcement';
import Feedback from './components/feedback';

function App() {
  return (
    <>
      <div class="content-container">
        <Nav />
        <Announcement />
      </div>
      <Feedback />
    </>
  );
}

export default App;
