import { StepContainer, StepName, StepContent, Text } from './stepBox.styles';
import PackLink from './packLink';
import { Container } from './packLink.styles';

function Announcement() {
  return (
    <StepContainer>
      <StepName>✦ Announcement ✦</StepName>
      <StepContent>
        <Text>
          We are retiring this tool on<strong> May 31, 2024</strong>. Please
          update any Coda buttons using this tool to use one of our Export
          Tables Coda packs below instead. Any buttons currently using this tool
          will stop working after May 31.
        </Text>
        <Container>
          <PackLink
            imageUrl={'/export-tables.png'}
            packUrl={'https://coda.io/packs/export-tables-10648'}
            name={'Export Tables'}
            description={
              'Export any Coda table to Excel or CSV with the click of a button. This is a direct replacement for codaexport.com'
            }
          ></PackLink>
          <PackLink
            imageUrl={'/export-tables-pro.png'}
            packUrl={'https://coda.io/packs/export-tables-pro-10652'}
            name={'Export Tables Pro'}
            description={
              'In addition to exporting, this pack lets you send tables as email attachments. All exported files also have properly formatted data and nice styling such as auto-fitted column widths. Try it out with a 2-week free trial'
            }
          ></PackLink>
        </Container>
      </StepContent>
    </StepContainer>
  );
}

export default Announcement;
