import styled from 'styled-components';

export const MessageBox = styled.div`
  min-height: 150px;
`;

export const Message = styled.div`
  margin: 30px 60px;
  padding: 12px;
  font-size: 22px;
  color: #fff;
  @media only screen and (max-width: 500px) {
    margin: 40px 25px 20px;
  }
`;

export const Link = styled.a`
  color: #4df7e6;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 40px;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 400;
  background: #234970;
  color: #fff;
  border: none;
  border-radius: 5px;
  min-width: 300px;
  box-shadow: 2px 2px 5px 2px #00000020;
  &:hover {
    cursor: pointer;
    background: #204266;
  }
  &:disabled {
    background: #476b8f;
    &:hover {
      cursor: default;
    }
  }
  @media only screen and (max-width: 420px) {
    padding: 6px 20px;
    margin-top: 40px;
    min-width: 20px;
  }
`;

export const IconContainer = styled.div`
  margin: 0 10px 0 -5px;
  display: flex;
  align-items: center;
  font-size: 30px;
  min-height: 30px;
  min-width: 30px;
`;

export const AlertBox = styled.div`
  margin-top: 20px;
  margin-left: 25px;
  @media only screen and (max-width: 500px) {
    margin-left: 10px;
  }
`;
