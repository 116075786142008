import styled from 'styled-components';

export const StepContainer = styled.div`
  background: #d7f4ff30;
  margin: 50px auto;
  border-radius: 5px;
  overflow: hidden;
  width: 80%;
  max-width: 1010px;
  box-shadow: 4px 4px 20px 5px #00000020;
  @media only screen and (max-width: 560px) {
    width: 85%;
  }
`;

export const StepName = styled.h2`
  background: #d7f4ff30;
  color: #ffffff;
  margin-top: 0;
  padding: 8px 15px;
  @media only screen and (max-width: 500px) {
    font-size: 22px;
  }
`;

export const SubHeading = styled.h3`
  color: #ffffff;
  margin: 40px 20px 0;
  padding-top: 20px;
  border-top: 1px solid #ffffff60;
  font-size: 22px;
`;

export const StepContent = styled.div`
  margin: 30px 30px 55px;
  text-align: center;
  padding-bottom: 20px;
`;

export const Text = styled.p`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 25px;
  text-align: left;
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

export const SmallText = styled(Text)`
  font-size: 16px;
  max-width: 96%;
  margin: 15px auto;
`;

export const Video = styled.video`
  max-width: 96%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 2px 2px 5px 2px #00000020;
  @media only screen and (max-width: 500px) {
    max-width: 100%;
  }
`;

export const Breakdown = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 820px) {
    grid-template-columns: 1fr;
  }
`;
