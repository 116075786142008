import {
  NavBar,
  Title,
  Image,
  Text,
  Container,
  Arrow,
  LogoBox,
} from './nav.styles';

const Nav = () => (
  <NavBar>
    <LogoBox>
      <Image src="/Coda.png" alt="Coda logo" />
      <Arrow>➟</Arrow>
      <Image src="/microsoft-excel-logo.png" alt="Microsoft Excel logo" />
    </LogoBox>
    <Title>Coda to Excel</Title>
    <Container target="_blank" href="https://www.littlerobot.ca/">
      <Text>A Little Robot App</Text>
      <Image src="/little-robot.png" alt="Coda to Excel logo" />
    </Container>
  </NavBar>
);

export default Nav;
