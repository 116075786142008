import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  border-radius: 10px;
  background: #435e7a10
    linear-gradient(340deg, #ffffff30, #ffffff30, #ffffff50, #ffffff75);
  display: flex;
  justify-content: start;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  margin-top: 25px;
  color: #fff;
  font-size: 18px;
  text-shadow: 2px 1px #00000010;
  box-shadow: 3px 3px 8px 2px #00000020;
  text-decoration: none;
  cursor: pointer;
  transition: transform 100ms ease-in-out;
  :hover {
    transform: scale(1.02);
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    margin: 15px;
    min-width: 200px;
    max-width: 300px;
  }
`;

export const Name = styled.h2`
  margin: 10px 15px 5px;
  text-align: left;
`;

export const Description = styled.p`
  text-align: left;
  margin: 5px 15px 15px;
`;

export const Image = styled.img`
  height: 150px;
  border-right: solid #62fcd5 5px;

  @media (max-width: 1000px) {
    height: auto;
  }
`;
