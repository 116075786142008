import styled from 'styled-components';
import TipsAndUpdates from '@mui/icons-material/TipsAndUpdates';

export const AlertBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 9;
  display: none;
  @media only screen and (max-width: 800px) {
    display: ${(props) => (props.expanded ? 'block' : 'none')};
    background-color: #41434760;
  }
`;

export const AlertBox = styled.div`
  z-index: 10;
  background: #234970;
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  padding: 8px 10px;
  border-radius: 18px;
  box-shadow: 4px 4px 20px 5px #00000020;
  display: flex;
  width: 400px;
  max-width: 90%;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: -20px;
  top: 25px;
  left: 25px;
  position: sticky;
  overflow: hidden;
  height: 20px;
  transition: width 200ms ease-in-out;
  -webkit-transition: width 200ms ease-in-out;
  transform: perspective(1000px) translateZ(0);
  &.collapsed {
    width: 16px;
  }
  @media only screen and (min-width: 800px) {
    :hover {
      width: 400px;
      box-shadow: 4px 4px 25px 5px #00000025;
      background: #204266;
      transform: perspective(1000px) translateZ(10px);
    }
  }
  span {
    height: 20px;
    overflow: hidden;
    word-wrap: break-word;
  }
  @keyframes slide {
    0% {
      transform: translateX(-500px);
    }
    80% {
      transform: translateX(-500px);
    }
    100% {
      transform: translateX(0);
    }
  }
  animation: 2s slide;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
    font-weight: 400;
    top: 15px;
    left: 10px;
    margin-bottom: -30px;
    width: 330px;
  }
`;

export const Lightbulb = styled(TipsAndUpdates)`
  margin-right: 10px;
  @media only screen and (max-width: 800px) {
    margin-right: 5px;
  }
`;
