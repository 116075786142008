import { AlertBox } from '../../components/export.styles';
import Alert from '../../components/alert';
import ExportMessage from '../../components/export';
import Nav from '../../components/nav';

function ExportPage() {
  const body = document.body;
  body.style.height = '300vh';
  body.style.overflow = 'hidden';

  document.title = 'Coda to Excel - Export';

  return (
    <>
      <Nav />
      <ExportMessage />
    </>
  );
}

export default ExportPage;
