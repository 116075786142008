import { AlertBackground, AlertBox, Lightbulb } from './alert.styles';
import { useState, useEffect } from 'react';
import mixpanelEvent from '../util/mixpanel';

function Alert() {
  const [offset, setOffset] = useState(0);
  const [isUpper, setIsUpper] = useState(true);
  const [expanded, setExpanded] = useState(false);

  const handleScroll = () => setOffset(window.pageYOffset);
  const openWindow = () => {
    if (expanded || isUpper || window.innerWidth >= 800) {
      window.open('https://coda.io/@courtney-milligan/export-tables-pro');
      mixpanelEvent('Coda Pack link opened');
      setExpanded(false);
    } else if (window.innerWidth < 800) setExpanded(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (offset > 76) {
      setIsUpper(false);
    } else {
      setIsUpper(true);
    }
  }, [offset, isUpper]);

  return (
    <>
      <AlertBackground
        expanded={expanded}
        onClick={() => setExpanded(false)}
        onTouchMove={() => setExpanded(false)}
      />
      <AlertBox
        className={isUpper || expanded ? '' : 'collapsed'}
        onClick={openWindow}
      >
        <Lightbulb fontSize="small" />
        <span>NEW: email tables and upload to Google Drive!</span>
      </AlertBox>
    </>
  );
}

export default Alert;
